import React from "react";
import "./App.css";
import image from "./heroimage.jpg"; 

const Hero = () => {
  return (
    <section className="hero">
      <img src={image} alt="Banner" className="hero-img" />
      <div className="hero-text">
        <h1>Where services meets clients!</h1>
        {/* <p>Your tagline goes here</p> */}
      </div>
    </section>
  );
};

export default Hero;
