import React from "react";
import "./App.css";

const Posts = () => {
  return (
    <section className="posts">
      <div className="post-container">
        <div className="post-sections">
          <section className="about-us">
            <h2>About Us</h2>
            <p>
              A Score is a leading platform that connects skilled workers with
              clients in need of their expertise. Our mission is to simplify the
              process of finding and offering work, ensuring a seamless
              experience for both parties.
            </p>
          </section>
          <section className="how-it-works">
            <h2>How It Works</h2>
            <p>1. Sign up and create your profile.</p>
            <p>2. Connect and communicate directly through our platform.</p>
            <p>3. Schedule a transaction.</p>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Posts;
