import React from "react";
import "./App.css";
import logo from "./Logo.png";

const Header = () => {
  return (
    <header className="header">
      <img src={logo} alt="Banner" className="header-img" />
      {/* <div className="name">A Score</div> */}
    </header>
  );
};

export default Header;
