import React from "react";
import "./App.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-info">
        <p>Email: info@ascore.com</p>
      </div>

      <p>&copy; 2024 A SCORE. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
